import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import PageBanner from '../components/PageBanner'
import MonsheimLieferzone from '../assets/images/monsheim-lieferzone.png'

const Floersheim = (props) => (
    <Layout>
        <Helmet>
            <title>Kostenlose Lieferung - Dogan Döner Pizza Haus - Online bestellen</title>
            <meta name="description" content="Dogan Döner Pizza Haus, Kostenlose Lieferung, Online Essen Bestellen" />
        </Helmet>
        <PageBanner pageTitle="Monsheim" location="monsheim" />

        <div id="main" class="location-main">
        <section id="two" className="spotlights">
                <section>
                    <a href="/generic" className="image">
                        <img src={MonsheimLieferzone} alt="Flörsheim-Dalsheim Dogan Lieferzone" />
                    </a>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h1>Kostenlose Lieferung</h1>
                                <h3>Mindestbestellwert /Lieferkosten</h3>
                            </header>

                            <ul className="actions">
                                <li><span className="delivery-zone" ></span>  min - 12,00 €, Gebühr - 0,00 €</li>
                                <li><span className="delivery-zone" ></span>  min - 15,00 €, Gebühr - 0,00 €</li>
                                <li><span className="delivery-zone" ></span>  min - 17,00 €, Gebühr - 0,00 €</li>
                                <li><span className="delivery-zone" ></span>  min - 20,00 €, Gebühr - 0,00 €</li>
                                <li><span className="delivery-zone" ></span>  min - 25,00 €, Gebühr - 0,00 €</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section>
            <section id="opening">
                <div className="inner">
                    <div>
                        <header className="major">
                            <h2>Öffnungszeiten Monsheim</h2>
                        </header>
                        <p>
                        Mo. - Sa. : 11.00 – 22.00 Uhr <br />
                        So. & Feiertag : 12.00 – 22.00 Uhr
                        </p>
                    </div>
                </div>
            </section>
        </div>

    </Layout>
)

export default Floersheim